import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ShyIcinOrtakService } from "../../../services/shyIcınOrtak/shy-icin-ortak.service";
import { GenelService } from "../../../services/genel/genel/genel.service";
import { OrtakTanimlarService } from "../../../services/ortakTanimlar/ortak-tanimlar.service";
import { TarihFormatService } from "src/app/shared/services/genel/genel/TarihFormat.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { TevkifatLimitTutarTarihEmitService } from "../../../services/emitService/tevkifat-limit-tutar-tarih-emit/tevkifat-limit-tutar-tarih-emit.service";

@Component({
  selector: "app-tevkifat-limit-tutar-tarih-shy",
  templateUrl: "./tevkifat-limit-tutar-tarih-shy.component.html",
  styleUrls: ["./tevkifat-limit-tutar-tarih-shy.component.scss"],
  animations: [
    trigger("slideToggle", [
      state("open", style({ height: "*", opacity: 1 })),
      state("closed", style({ height: "0px", opacity: 0 })),
      transition("open <=> closed", [animate("300ms ease-in-out")]),
    ]),
  ],
})
export class TevkifatLimitTutarTarihShyComponent implements OnInit {
  provizyonId;
  provizyonGetirData: any;

  constructor(
    private fb: FormBuilder,
    private shyIcinOrtak: ShyIcinOrtakService,
    private genel: GenelService,
    private ortakTanimlar: OrtakTanimlarService,
    private tarihFormat: TarihFormatService,
    private tevkifatLimitTutarTarihEmitService: TevkifatLimitTutarTarihEmitService
  ) {}

  validationTevkifatTarihForm: FormGroup;
  tevLimTutarTarih: any;

  ngOnInit() {
    this.provizyonId = localStorage.getItem("provizyonId");
    this.validationTevkifatTarihForm = this.fb.group({
      inputTevLimTutarTarih: ["", [Validators.required]],
    });
    this.provizyonGetir();
  }

  async provizyonGetir() {
    await this.shyIcinOrtak.provizyonGetir(this.provizyonId).then((res) => {
      this.provizyonGetirData = res?.data;

      this.tevLimTutarTarih = this.provizyonGetirData?.tevLimTutarTarih;

      const formattedDate = this.provizyonGetirData?.tevLimTutarTarih
        ? this.tevLimTutarTarih.split("T")[0]
        : this.provizyonGetirData?.hizmetTarih;

      // Formu güncelle
      this.validationTevkifatTarihForm.patchValue({
        inputTevLimTutarTarih: formattedDate,
      });

      this.tevkifatKdvOranGetir(this.provizyonGetirData.hizmetTarih);
    });
  }
  changeTevLimTutarTarih(date) {
    this.tevLimTutarTarih = date;
  }

  tevkifatGrupOran: any;
  async tevkifatKdvOranGetir(tarih) {
    let d = new Date(tarih);
    let tarih1 = this.tarihFormat.formatDate(d);
    await this.ortakTanimlar
      .tevkifatKdvOraniGetir(
        tarih1,
        this.provizyonGetirData.provizyonFaturaDurumTanimID
      )
      .then((res) => {
        this.tevkifatGrupOran = res?.data;
      });
  }

  tevkifatLimitTutarTarihGuncelleFormVisible: boolean = false;
  tevkifatLimitTutarTarihGuncelleGosterBtn() {
    this.tevkifatLimitTutarTarihGuncelleFormVisible =
      !this.tevkifatLimitTutarTarihGuncelleFormVisible;
  }

  async tevkifatLimitTutarTarihGuncelleHandler() {
    //tevkifatLimitTutarTarihGuncelle
    this.shyIcinOrtak
      .tevkifatLimitTutarTarihGuncelle(this.tevLimTutarTarih, this.provizyonId)
      .then((res) => {
        this.genel.mesajKontrol(res.basariliMi, res.mesaj[0]);
        this.tevkifatLimitTutarTarihEmitService.getEmitServis(null);
      });
  }

  async tevkifatLimitTutarTarihGuncelleIptal() {
    this.tevkifatLimitTutarTarihGuncelleFormVisible = false;
    this.tevLimTutarTarih = this.provizyonGetirData?.tevLimTutarTarih;

    const formattedDate = this.tevLimTutarTarih.split("T")[0];

    // Formu güncelle
    this.validationTevkifatTarihForm.patchValue({
      inputTevLimTutarTarih: formattedDate,
    });
  }
}
