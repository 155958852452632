import { Component, OnInit } from "@angular/core";
import { VersionService } from "src/app/shared/services/version.service";
@Component({
  selector: "app-version",
  templateUrl: "./version.component.html",
  styleUrls: ["./version.component.scss"],
})
export class VersionComponent implements OnInit {
  constructor(private versionHttp: VersionService) {}

  ngOnInit(): void {
    this.getVersionApi();
  }

  versionApi;
  async getVersionApi() {
    this.versionHttp.getVersionApi().then((res) => {
      this.versionApi = res.version;
    });
  }
}
